<template>
    <!--<v-container fluid class="home" fill-height style="max-height: 100vh;">-->
    <!--class="bonus-background"-->
    <!--<v-container fluid>-->
        <v-card class="transparent-body" elevation="0">
            <!--@click.stop-->
            <!--<v-card-title dense dark color="pink darken-4">Список пользователей</v-card-title>-->
            <v-sheet elevation="12">
                <v-toolbar dense dark color="indigo darken-4">
                    <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
                    <v-toolbar-title>{{title}}</v-toolbar-title>
                </v-toolbar>
                <!--center-active-->
                <!--centered-->
                <v-tabs
                        background-color="indigo"
                        dark
                        right
                        show-arrows
                >
                    <v-tab
                            v-for="tabitem in tablist"
                            :key="tabitem.value"
                            :disabled="!((tabitem.visible == 'all') && (tabitem.new || id))"
                            :to="tabitem.href"
                    >
                        <!--:to="'/account/' + (id ? id : 'add') + tabitem.href"-->
                        {{ tabitem.text }}
                    </v-tab>
                </v-tabs>
            </v-sheet>

            <v-container fluid class="d-flex justify-center px-0">
                <router-view @update-account="updateAccount" @update-bank="updateBank"/>
            </v-container>
        </v-card>
    <!--</v-container>-->
</template>

<script>

  export default {
    name: 'Account',
//    props: {holderType: String},
    props: ['param', 'holder'],

    components: {
    },

    computed: {
      title() {
        return (this.number ? 'Счет #' + this.number +
          ( this.balance ? ', баланс: ' + this.balance.balanceUnit + '.' + this.balance.balanceHundredths: ''): '');
      },
      tablist() {
        return [
            {text: 'Информация', value: 'account', visible: 'all', href: '/account/' + this.id, new: true},
            {text: 'Операции', value: 'bank', visible: 'all', href: '/account/' + this.id + '/bank', new: false},
            {text: 'Holder', value: 'holder', visible: (this.holderId ? 'all' : 'no'), href: '/holder/'+ this.holderId, new: false}
            ];
      }
    },

    /*beforeRouteUpdate (to, from, next) {
      //console.log(to);
      if (to.params.accountId == 'add'){
        this.id = null;
        //this.title = 'Новый';
      }
      else if (!isNaN(parseInt(to.params.accountId,10))){
        //потом сделать наоборот, если цифра то гет данные иначе новый
        this.id = parseInt(to.params.accountId,10);
      }

      next();
    },*/

    watch: {
      param: {
        handler: function(newVal, oldVal) {
          //console.log('account.vue watch param');
          this.init();
          },
        //immediate: true
      },

      id: {//это гавно конечно полное учитывая код сверху но это появилось первым
        handler: function(newVal, oldVal) {
          //console.log('account.vue watch id, newVal: ' +  newVal + ', oldVal: ' + oldVal + ', routename: ' + this.$route.name);
          if (oldVal !== newVal && this.$route.name !== 'AccountCommon'){
            this.get();
          }
        },
        //immediate: true
      },
    },

    data(){
      return {
/*
        tablist: [
          {text: 'Информация', value: 'account', visible: 'all', href: '', new: true},
          {text: 'Операции', value: 'bank', visible: 'all', href: '/bank', new: false},
          {text: 'Holder', value: 'holder', visible: 'all', href: '/holder/holderId', new: false},
        ],
*/
        id: null,
        holderId: null,
        //title: '',
        number: null,
        balance:{},
        //tab: null
        //tab2: null
      }
    },

    mounted() {
      //console.log('account.vue mounted');
      this.init();
    },

    methods: {
      updateAccount(data){//тут может быть гавно в том что есть не все поля
        //console.log('account.vue updateAccount');
        this.id = data.accountId;
        this.number = data.accountNumber;
        this.holderId = data.holderId;
        this.balance = data.balance;
      },

      updateBank(data){//тут может быть гавно в том что есть не все поля
        //console.log('account.vue updateBank');
        this.get();
      },

      init(){
        //console.log(this.param);
        //console.log('account.vue init()');
        this.id = this.param;
        //this.title = '<Новый>';
      },

      get(){
        this.$store.dispatch("setLoading", true);
        //console.log('account.vue get()');
        try{
          this.$store.dispatch("getHolderAccount", this.id)
            .then((response) =>{
              //console.log(response);
              if (response.data){
                this.balance = response.data.balance;
                this.number = response.data.number;
                this.holderId = response.data.holderId;
              }
              else{
                throw new Error("Ошибка получения данных");
              }
            })
            .then(null, (error) =>{
              this.$store.dispatch("showMessage", { text: 'Ошибка получения account: ' + error.message, error: true });
            })
            .finally(() =>{
              this.$store.dispatch("setLoading", false);
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }
      },

    }
  };

/*
  One last thing — a button linking to a specific tab will simply look as follows:
  <v-btn to="/page?tab=one">
    Tab One
  </v-btn>
  Note that the button destination can be written as: :to="{ path: '/', query: { tab: 'two' } }" if you prefer the object syntax.
  The same will also work for router links.
*/
</script>

<style scoped>
    .transparent-body {
        background: transparent
    }
    /*.v-tabs__content {*/
        /*background-color: green;*/
        /*!*height: 100px;*!*/
    /*}*/
/*
    .v-tabs-items {
        !*background-color: rgb(0, 0, 0, 0.0);*!
        background: transparent
        !*height: 100px;*!
    }
*/
    /*.v-window-item--active {*/
        /*background-color: rgb(0, 0, 0, 0.0);*/
        /*color: rgb(0, 0, 0, 0.0);*/
        /*!*height: 100px;*!*/
    /*}*/
</style>
