<template>
    <!--<v-container fluid>-->
    <!--<div class="flex-grow-1">-->
    <div class="flex-grow-1">
        <v-scale-transition>
            <v-card class="elevation-12 fill-height"
                    color="rgb(0, 0, 0, 0.5)"
                    v-show="show"
                    >
                <!--id="accountList-v-card"-->
                <!--@click.self.prevent-->
                <v-toolbar dense dark color="pink darken-4" v-if="paramTitle">
                    <v-toolbar-title>Список счетов</v-toolbar-title>
                </v-toolbar>
                <v-container fluid v-if="paramFilter">
                    <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select
                                    dense
                                    :items="typeItems" v-model="type"
                                    label="Тип" outlined hide-details return-object
                            >
                                <!--v-on:change="changeFilter($event)"-->
                            </v-select>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select
                                    dense
                                    :items="statusItems" v-model="status"
                                    label="Статус" outlined hide-details return-object
                            >
                                <!--v-on:change="changeFilter($event)"-->
                            </v-select>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="6">
                            <v-text-field
                                    dense
                                    v-model="nameFilter"
                                    label="Наименование"
                                    outlined
                                    hide-details
                                    clearable
                                    append-outer-icon="search"
                                    @click:clear="clearNameFilter($event)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-data-table
                        class="transparent"
                        ref="vDataTable"
                        :headers="computedHeaders"
                        :items="items"
                        show-expand
                        loader-height="1"
                        :expanded.sync="expanded"
                        v-bind:item-key="getItemKey()"
                        :items-per-page="itemsPerPageCount"
                        :server-items-length="totalItems"
                        hide-default-footer
                        :hide-default-header="!paramHeader"
                        @click:row="rowClick">
                    <template v-slot:item.id="{ item }">
                        {{idSplit(item.id)}}
                    </template>
                    <template v-slot:item.balance="{ item }">
                        {{balanceFormat(item.balance)}}
                    </template>
                    <template v-slot:item.type="{ item }">
                            {{ item.type == 'bonus' ? 'Бонусный' : 'Не известно' }}
                    </template>
                    <!--<template v-slot:item.data="{ item }">-->
                        <!--{{ JSON.stringify(item.data)}}-->
                    <!--</template>-->
                    <template v-slot:item.holderId="{ item }">
                        <!--https://stackoverflow.com/questions/44815465/how-to-stop-router-link-from-sending-the-user-to-another-page-->
                        <!--<router-link @click.stop="" :to="'/holder/'+item.holderId">{{ item.holderId }}</router-link>-->
                        <div class="d-flex justify-space-around">
                            <v-btn v-if="item.holderId" text outlined @click.stop="" :to="'/holders/'+item.holderId">{{ item.holderId }}</v-btn>
                        </div>
                    </template>
                    <template v-slot:item.status="{ item }">
                        {{ statusString(item.status) }}
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length"
                            :key="getItemKey(item)"
                            :class="{'ma-0 pa-0': true,
                                    'expanded-closing': !transitioned[getItemId(item)]}"
                            style="height: auto;"
                            >
                            <v-expand-transition>
                                <div v-show="transitioned[getItemId(item)]" class="pa-0 ma-0">
                                    <!--<v-container fluid class="d-flex pa-0 ma-0">-->
                                        <div class="d-flex flex-column justify-space-between flex-wrap py-3">
                                            <account-form :param="idSplit(item.id)" @update-account="updateAccount"></account-form>
                                            <!--<bank-list :account="item.number"-->
                                                <!--:holder="item.holderId"-->
                                                <!--:paramHeader="true"-->
                                                <!--:paramTitle="true"-->
                                                <!--@update-account="updateAccount"-->
                                                <!--:paramPagination="true"-->
                                                <!--:paramFilter="true"-->
                                            <!--&gt;</bank-list>-->
                                        </div>
                                </div>
                            </v-expand-transition>
                        </td>
                    </template>
                    <template v-slot:item.data-table-expand="props">
                        <v-icon :class="{ 'v-data-table__expand-icon': true,
                                          'v-data-table__expand-icon--active': props.isExpanded && transitioned[getItemId(props.item)] }"
                        >
                            <!--@click="toggleExpand(props)"-->
                            expand_more
                        </v-icon>
                    </template>
                </v-data-table>
                <!--<v-container fluid>-->
                <div class="pagination" v-if="paramPagination">
                    <!--<v-container fluid pa-0>-->
                    <v-row class="d-flex align-center pa-0 ma-0">
                        <!--<v-col class="d-flex justify-start flex-grow-0 flex-shrink-1" sm="2" xs="2">-->
                        <v-col class="flex-grow-0 flex-shrink-1">
                            <!--class="ml-4 mr-4"-->
                            <!--v-model="pagesSelected"-->
                            <v-select
                                    v-model="itemsPerPage"
                                    :items="itemsPerPageItems"
                                    label="Строк" outlined hide-details return-object dense
                            >
                                <!--v-on:change="changePages($event)"-->
                            </v-select>
                        </v-col>
                        <!--<v-col class="d-flex justify-center">-->
                        <v-col class="">
                                <v-pagination
                                        @input="paginationChangeHandler"
                                        :value="page"
                                        :length="pageCount"
                                        :total-visible="6"
                                ></v-pagination>
                        </v-col>
                        <!--<v-col class="d-flex text-no-wrap ml-4 mr-4 flex-grow-0 flex-shrink-1 justify-end">-->
                        <v-col class="text-no-wrap flex-grow-0 flex-shrink-1 justify-end">
                            <v-chip outlined label>
                                {{(page - 1)* itemsPerPageCount + 1}} - {{(page * itemsPerPageCount > totalItems ? totalItems : page * itemsPerPageCount)}} из {{totalItems}}
                            </v-chip>
                        </v-col>
                    </v-row>
                    <!--</v-container>-->
                </div>
                <!--</v-container>-->
                <!--rounded-->
                <!--contained-->
                <!--<button type="button" class="transition-swing v-btn v-btn&#45;&#45;bottom v-btn&#45;&#45;contained v-btn&#45;&#45;fab v-btn&#45;&#45;fixed v-btn&#45;&#45;right v-btn&#45;&#45;round theme&#45;&#45;light v-size&#45;&#45;large primary" aria-label="Scroll to top" title="Scroll to top" style="z-index: 6; margin-bottom: 88px; transform-origin: center center;"><span class="v-btn__content"><span aria-hidden="true" class="v-icon notranslate theme&#45;&#45;light"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"></path></svg></span></span></button>-->
            </v-card>
        </v-scale-transition>
        <v-fab-transition>
            <!--large-->
            <v-btn  to="account/add"
                    v-show="false && !addHidden"
                    dark
                    elevation="12"
                    fixed
                    fab
                    bottom
                    right
                    class="mb-16 mr-8 pink">
                <v-icon>add</v-icon>
            </v-btn>
        </v-fab-transition>
    </div>
    <!--</v-container>-->
</template>

<script>
  import AccountForm from '@/components/AccountForm';
  export default {
    // props:{
    //   holderType: {type: String, default: ''}
    // },
    props: ['holder', 'paramTitle', 'paramFilter', 'paramPagination', 'paramHeader'],
    name: 'AccountList',
    components: {
      AccountForm
    },

    data(){
      return {
        holderId: null,
        holderType: null,

        addHidden: true,
        show: false,
        //filter

        statusItems: [
          {text: 'Любой', value: ''},
          {text: 'Активен', value: 'active'},
          {text: 'Выключен', value: 'disabled'},
        ],
        typeItems: [
          {text: 'Любой', value: ''},
          {text: 'Клиента', value: 'person'},
          {text: 'Партнера', value: 'partner'},
        ],
        itemsPerPageItems: [{text: '5', value: 5},{text: '10', value: 10},{text: '20', value: 20},{text: 'Все', value: -1}],
        items: [],
        totalItems: 0,

        //table
        transitioned: [],
        expanded: [],
        singleExpand: true,
        closeTimeouts: {},

        headers: [
          { text: 'Id', align: 'left', sortable: false, value: 'id', divider: true, mobile: true},
          { text: 'Номер', value: 'number', mobile: true, divider: true, sortable: false},
          { text: 'Баланс', value: 'balance', align: 'right', divider: true, mobile: true, sortable: false},
          { text: 'Holder', value: 'holderId', align: 'center',mobile: true, divider: true, sortable: false},
          { text: 'Статус', value: 'status', divider: true, mobile: false, sortable: false},
          { text: 'Тип', value: 'type', divider: true, mobile: false, sortable: false},
          { text: 'Cоздан', value: 'createTime', align: 'right', divider: true, mobile: false, sortable: false},
          // { text: 'Data', value: 'data', align: 'right', divider: true, mobile: false, sortable: false},
          //{ text: 'Изменен', value: 'updateTime', align: 'right', divider: true, mobile: false, sortable: false},
          { text: '', value: 'data-table-expand', mobile: true },
        ],
      }
    },
    //https://ru.vuejs.org/v2/api/#watch
    watch: {

      holder:
        function(v){
          //console.log('holder watch: ' + v);
          this.init();
        },
      
      '$route.query': {
        handler: function(newVal, oldVal) {
          //console.log(oldVal);
          //console.log(newVal);
          if ( (oldVal && oldVal.name !== newVal.name)
                || (oldVal && oldVal.status !== newVal.status)
                || (oldVal && oldVal.type !== newVal.type)
            || (oldVal && oldVal.itemsPerPage !== newVal.itemsPerPage)){
            if (this.page !== 1){
              this.page = 1;
            }
          }

          if (newVal != oldVal) {
            //this.term = this.$route.query.term;
            //console.log('watch');
            this.get('watch');
          }
        },
        //immediate: true
      },

      singleExpand (v) {
        if (!v) return;
        // Single expand enabled. Hide all but the first expanded item
        this.expanded.forEach((item, i) => {
          if (i > 0) this.closeExpand(item);
        })
      }
    },

    mounted(){
      this.init();
    },

    computed: {
      itemsPerPage: {
        set(v){
          if (this.$route.query.itemsPerPage !== v.value){
            this.$router.push({ query: { ...this.$route.query, itemsPerPage: v.value } });
          }
        },
        get(){
          const findItems = this.itemsPerPageItems.filter((item) => (item.value == this.$route.query.itemsPerPage));
          //console.log(findItems);

          if (findItems.length){
            return findItems[0];
          }
          else{
            return this.itemsPerPageItems[1];
          }
        }
      },

      //добавить в вотч
      status:{
        set(status){
          if (this.$route.query.status !== status.value){
            this.$router.push({ query: { ...this.$route.query, status: status.value } });
          }
        },
        get(){
          const findItems = this.statusItems.filter((item) => (item.value == this.$route.query.status));
          //console.log(findItems);

          if (findItems.length){
            return findItems[0];
          }
          else{
            //this.status = this.statusItems[0];
            //if (this.$route.query.status !== this.statusItems[0].value){
              //this.$router.push({ query: { ...this.$route.query, status: this.statusItems[0].value } });
            //}
            return this.statusItems[0];
          }
        }
      },

      type:{
        set(type){
          //console.log('saaa');
          if (this.$route.query.type !== type.value){
            this.$router.push({ query: { ...this.$route.query, type: type.value } });
          }
        },
        get(){
          const findItems = this.typeItems.filter((item) => (item.value == this.$route.query.type));
          //console.log(findItems);

          if (findItems.length){
            return findItems[0];
          }
          else{
            return this.typeItems[0];
          }
        }
      },

      page:{
        set(page){
          this.$router.push({ query: { ...this.$route.query, page } });
        },
        get(){
          if (this.$route.query.page){
            return +this.$route.query.page;
          }
          else
            return 1;
        }
      },

      nameFilter:{
          set(name){
            this.$router.push({ query: { ...this.$route.query, name } });
          },
          get(){
            if (this.$route.query.name){
              return this.$route.query.name;
            }
            else
                return '';
          }
        },

      itemsPerPageCount(){
        if (this.itemsPerPage.value > 0){
            return this.itemsPerPage.value;
        }
        else{
            return this.totalItems;
        }
      },

      pageCount() {
        //console.log(this.pagination);
        if (!this.itemsPerPage.value || !this.totalItems) {
          return 0;
        }
        if (this.itemsPerPage.value == -1){
          return 1;
        }
        return Math.ceil(this.totalItems / this.itemsPerPage.value);
      },

      computedHeaders () {
        return this.headers.filter((i) =>
          ( ((i.value == 'holderId' && !this.holder) || i.value !== 'holderId')
            && (this.$vuetify.breakpoint.mdAndUp || (this.$vuetify.breakpoint.smAndDown && i.mobile)) ))
          .map((i) => {
            return i;
          });
      },
    },

    methods: {
      balanceFormat(balance){
        //console.log(this.balance);
        return ( balance.value ? balance.balanceUnit + '.' + balance.balanceHundredths: '');
      },

      idSplit(id) {
        let array = String.prototype.split.call(id, '::');
        if (id && array.length > 1 ) {
          //console.log(array[1]);
          return array[1];
        }
        else
          return '';
      },

      statusString(value){
        switch(value){
          case 'active': return 'Активен';
          case 'finished': return 'Выключен';
        }
      },
      
      updateAccount(data){//тут может быть гавно в том что есть не все поля
        //console.log(data);
        //console.log(this.tab);
        //this.closeAll();
        this.get('update');
        //console.log('discounts update');
      },

      init(){
        //console.log('htype'+this.holderType);
        this.show = true;
        if (!this.holder){
          this.holderId = null;
          //this.initialized = true;
          //this.$emit('update-title', 'Пользователь: Новый');
          //this.$emit('update-title', {title: '<Новый>'});
        }
        else {
          this.holderId = this.holder;
          //this.get('getH on mounted');
          this.addHidden = false;
        }
        this.get('init');
      },

      rowClick(item,props){
        this.toggleExpand(props);
      },

      clearNameFilter(e){
        this.nameFilter = '';
        this.page = 1;
        //this.get('clear');
        //console.log(e);
      },

      paginationChangeHandler(pageNumber) {
        if (this.page != pageNumber){
          this.page = pageNumber;
          //this.get('getH on paginator');
        }
      },

      get(a){
        this.$store.dispatch("setLoading", true);

        try{
          const reqParams = {
            holderId: this.holderId,
            name: this.nameFilter,
            type: this.type.value,
            status: this.status.value,
            page: this.page,
            itemsPerPage: this.itemsPerPageCount
          };
          //console.log(reqParams);
          let reqMethod = '';
          if (this.holderId){
            reqMethod = 'getHolderAccounts';
          }
          else{
            reqMethod = 'getAccounts';
          }

          this.$store.dispatch(reqMethod, reqParams)
            .then((response) =>{
              //console.log(response);
              //this.partners = response.data.data.map((i) => ({id: i.id, name: i.shortName, holderRouteId: i.holderRouteId}));
              this.items = response.data.data;
              this.totalItems = response.data.count;
              //this.holderType = response.data.holderType;
            })
            .then(null, (error) =>{
              //console.log(error);
              this.$store.dispatch("showMessage", { text: 'Ошибка получения accounts: ' + error.message, error: true });
            })
            .finally(() =>{
              this.$store.dispatch("setLoading", false);
              //this.loading = false;
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }
      },

      toggleExpand (props) {
        const item = props.item;
        const id = this.getItemId(item);
        if (props.isExpanded && this.transitioned[id]) {
          // If we're expanded and not in the process of closing, close
          this.closeExpand(item);
        } else {
          // If we're closed or in the process of closing, expand
          // Stop us from closing if a close transition was started
          clearTimeout(this.closeTimeouts[id]);
          // Tell v-data-table to add the expansion content for the item
          props.expand(true);
          // Show expansion content with transition animation after it's had time to get added to the DOM
          this.$nextTick(() => this.$set(this.transitioned, id, true));
          // Hide all other expanded items if single-expand
          if (this.singleExpand) this.$nextTick(() => this.expanded.forEach(i => i !== item && this.closeExpand(i)));
        }
      },

      closeAll(){
        this.$nextTick(() => this.expanded.forEach(i => this.closeExpand(i)));
      },

      closeExpand (item) {
        //console.log(item);
        const id = this.getItemId(item);
        // Mark that this item is in the process of closing
        this.$set(this.transitioned, id, false);
        // Remove expansion content from DOM after transition animation has had enough time to finish
        this.closeTimeouts[id] = setTimeout(() => this.$refs.vDataTable.expand(item, false), 600);
      },

      getItemId (item) {
        return item[this.getItemKey()]; // Must be uid of record (would be nice if v-data-table exposed a "getItemKey" method)
        //return item.name;
      },

      getItemKey(){
        return "id";
        //return "name";
      }
    }
  };
</script>

<style scoped>
</style>
