<template>
    <!--<v-container fluid class="home" fill-height style="max-height: 100vh;">-->
    <v-container fluid class="bonus-background">
        <account v-if="param" :param="param"></account>
        <account-list v-else :paramPagination="true" :paramFilter="true" :paramTitle="true" :paramHeader="true"/>
    </v-container>
</template>

<script>
  import AccountList from '@/components/AccountList';
  import Account from '@/components/Account';

  export default {
    name: 'Accounts',
//    props: {holderType: String},
    props: ['param'],
    components: {
      Account,
      AccountList
    },
    mounted() {
      //console.log(this.$route);
      //console.log(this.param);
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
